<template>
  <table-view
    ref="tableView"
    url="/repair/listForPage"
    :filterable="false"
    :filter-form="dataForm"
    :batch-removable="true"
    delete-url="/repair/deleteById"
    :addable="isAuthed('car_repair_add')"
    :editable="isAuthed('car_repair_update')"
    :headers="headers"
    show-index
    selectable
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-input v-model="dataForm.number" clearable placeholder="自编号"></el-input>
      <!-- <el-select v-model="dataForm.baseId" clearable filterable placeholder="基地">
        <el-option v-for="item in baseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select> -->
      <el-select v-model="dataForm.infoStatus" clearable filterable placeholder="状态">
        <el-option v-for="item in statusList" :key="item.dictId" :label="item.dictName" :value="item.dictId"></el-option>
      </el-select>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'car-repair',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'number', label: '自编号', width: 120 },
        { id: 2, prop: 'baseName', label: '所属基地', width: 120 },
        { id: 3,
          prop: 'type',
          label: '维修类型',
          minWidth: 120,
          formatter: (row, column, cellValue) => {
            switch (cellValue) {
              case 1:
                return '工地急修'
              case 2:
                return '预约维修'
            }
          }
        },
        { id: 4,
          prop: 'infoStatus',
          label: '状态',
          width: 80,
          formatter: (row, column, cellValue) => {
            // 1 待完成 2 进行中 3 已完成
            switch (cellValue) {
              case 0:
                return '被拒绝'
              case 1:
                return '待审核'
              case 2:
                return '待维修'
              case 3:
                return '正在赶来'
              case 4:
                return '维修中'
              case 5:
                return '维修完成'
              case 6:
                return '确认完成'
            }
          }
        },
        { id: 5, prop: 'faultNote', label: '故障描述', width: 80 },
        { id: 6,
          prop: 'repairTime',
          label: '期望维修时间',
          minWidth: 150,
          formatter: (row, column, cellValue) => {
            return cellValue.substring(0, 10)
          }
        },
        { id: 7, prop: 'userName', label: '发起人', width: 100 },
        // { id: 8, prop: 'verifyUserName', label: '审批人', width: 100 },
        { id: 9, prop: 'repairUserName', label: '维修工程师', width: 100 },
        { id: 10, prop: 'createTime', label: '提交时间', minWidth: 150 },
        { id: 11, prop: 'repairStartTime', label: '开始维修时间', minWidth: 150 },
        { id: 12, prop: 'repairCompleteTime', label: '维修完成时间', minWidth: 150 },
        { id: 13, prop: 'repairConfirmTime', label: '确认完成时间', minWidth: 150 }
      ],
      dataForm: {
        number: null,
        companyList: null,
        infoStatus: null
      },
      baseList: [],
      statusList: []
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    }
  },

  async created () {
    let data = await this.$http({
      url: this.$http.adornUrl('/user/base/list'),
      method: 'post'
    })
    this.companyList = data.datas
    data = await this.$http({
      url: this.$http.adornUrl('/common/repairStatusList'),
      method: 'post'
    })
    this.statusList = data.datas
  }
}
</script>
